<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
<!--      <el-table-column type="expand" label="#" >-->
<!--        <template #default="scope">-->
<!--          <el-scrollbar max-height="300px">-->
<!--            <div>-->
<!--              {{scope.row.content}}-->
<!--            </div>-->
<!--          </el-scrollbar>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column  align="center"-->
<!--                        type="index"-->
<!--                         width="55" label="#" >-->
<!--      </el-table-column>-->
      <el-table-column prop="title" label="广告标题" >
      </el-table-column>
      <el-table-column prop="url" label="其它网址" >
      </el-table-column>
      <el-table-column width="70" prop="platform" label="平台" >
        <template #default="scope">
          <span v-if="scope.row.platform==0">客户端</span>
          <span v-if="scope.row.platform==1">师傅端</span>
        </template>
      </el-table-column>
      <el-table-column width="70" prop="space_count" label="发布" >
        <template #default="scope">
          <el-tag size="mini" v-if="scope.row.space_count>0">已发布</el-tag>
<!--          <span v-if="scope.row.space_count==1">师傅端</span>-->
        </template>
      </el-table-column>
      <el-table-column prop="" width="80" label="广告位" >
        <template #default="scope">
           <span v-for="(item,i) in space">
           <span v-if="item.id==scope.row.space">{{item.name}}</span>
         </span>
        </template>
      </el-table-column>
      <el-table-column prop="name" width="80" label="图片" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
            <el-image
                v-if="scope.row.pic_url"
                style="width: 50px; height: 50px"
                :src="scope.row.pic_url"
                :preview-src-list="[scope.row.pic_url]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="时间" >
      </el-table-column>
      <el-table-column prop="address" label="操作" width="175">
        <template #header>
          <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary"  @click="skuValueData(scope.row)">广告位</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="padding-right: 15px;height:calc(100% - 30px) ">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="70px"
          class="demo-ruleForm">
        <el-form-item label="标题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="平台" prop="platform">
          <el-select style="width: 100%;" v-model="ruleForm.platform" >
            <el-option
                v-for="item in brand"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其它网址" prop="">
          <el-input v-model="ruleForm.url"></el-input>
        </el-form-item>
        <div style="width: calc(100%)">
          <ueItem :content="ruleForm.content" :name="'conten'" @getContent="getContent"></ueItem>
          <!--          <vue-ueditor-wrap v-model="ruleForm.content" :config="editorConfig" editor-id="editor-demo-01"></vue-ueditor-wrap>-->
        </div>
      </el-form>
      </el-scrollbar>
      <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
      <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
    </el-drawer>
    <el-drawer
        :title="'('+skuValName+')广告位'"
        v-model="skuValdrawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
<!--      <div>-->
<!--        <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords_Sku"></el-input>-->
<!--        <el-button @click="searchSku()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>-->
<!--      </div>-->
      <el-table  height="calc(100vh - 230px)" :data="tableDataSku" style="width: 100%;">
        <el-table-column prop="name" label="广告位" >
          <template #default="scope">
           <span v-for="(item,i) in space">
            <span v-if="item.id==scope.row.space">{{item.name}}</span>
           </span>
          </template>
        </el-table-column>
        <el-table-column width="79" prop="name" label="图片" >
          <template #default="scope">
            <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
              <el-image
                  v-if="scope.row.pic_url"
                  style="width: 50px; height: 50px"
                  :src="scope.row.pic_url"
                  :preview-src-list="[scope.row.pic_url]">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="55" prop="sequence" label="排序" >
        </el-table-column>
        <el-table-column prop="show_way" label="显示方式" >
          <template #default="scope">
           <span v-for="(item,i) in wayArr">
            <span v-if="item.id==scope.row.show_way">{{item.name}}</span>
           </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="110">
          <template #header>
            <el-button :disabled="tableDataSku.length>0" @click="addSku()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upDataSku(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleDataSku(scope.row)"></el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChangeSku"
            @current-change="handleCurrentChangeSku"
            :current-page="pageSku"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limitSku"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalSku">
        </el-pagination>
      </div>
      <el-drawer
          :title="'('+skuValName+')广告位新增/修改'"
          v-model="addskuVal"
          size="40%"
          direction="rtl"
          :close-on-click-modal="false"
          destroy-on-close>
        <el-form
            :model="skuValForm"
            :rules="skuValrules"
            ref="skuValForm"
            label-width="120px"
            class="demo-ruleForm">
          <el-form-item label="广告位" prop="space">
            <el-select style="width: 100%;" v-model="skuValForm.space" >
              <el-option
                  v-for="item in space"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="显示方式" prop="show_way">
            <el-select style="width: 100%;" v-model="skuValForm.show_way" >
              <el-option
                  v-for="item in wayArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始有效时间" prop="">
            <el-date-picker style="width: 100%" value-format="YYYY-MM-DD" v-model="skuValForm.start_date" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束有效时间" prop="">
            <el-date-picker style="width: 100%" value-format="YYYY-MM-DD" v-model="skuValForm.end_date" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="序号" prop="">
            <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="skuValForm.sequence"></el-input-number>
          </el-form-item>
          <el-form-item  label="图片" prop="pic_url">
            <el-upload
                style=" width: 100px;height: 100px"
                class="avatar-uploader"
                :action="UpURL"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="httpRequest"
                :before-upload="beforeAvatarUpload">
              <img v-if="skuValForm.pic_url" :src="skuValForm.pic_url" class="avatar" />
              <i v-else  class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="color: #999999"><span style="font-size: 16px" class="el-icon-info"> </span>图片尺寸 宽 1242 X 高 2208</p>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-if="skuValForm.id" :loading="login" @click="okSkuValAdd('skuValForm',2)">修改</el-button>
            <el-button type="primary" v-else :loading="login" @click="okSkuValAdd('skuValForm',1)">保存</el-button>
          </el-form-item>
        </el-form>
      </el-drawer>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {getArea, getIMG, getUQIMGurl} from "@/api/common";
import * as axios from "@/api/advertiApi"//本页面接口
import ueItem from '../ue/ueItem'
export default {
  name: 'Specs',
  components:{
    ueItem,
  },
  data(){
    return{
      UpURL:'',
      skuValName:'',
      login:false,
      drawer:false,
      skuValdrawer:false,
      addskuVal:false,
      skurowData:[],
      tableData:[],
      areaArr:[],//城市
      page:1,
      limit:10,
      total:0,
      keywords:'',
      tableDataSku:[],
      pageSku:1,
      limitSku:10,
      totalSku:0,
      keywords_Sku:'',
      wayArr:[
        {
          id:0,
          name:'每次启动',
        },
        {
          id:1,
          name:'一天一次',
        },
      ],
      brand:[
        {
          id:0,
          name:'客户端',
        },
        {
          id:1,
          name:'师傅端',
        },
      ],
      space:[
        {
          id:0,
          name:'启动页',
        },
        {
          id:1,
          name:'首页弹框',
        },
      ],
      ruleForm:{
        title:'',
        platform:0,
        url:0,
        content:'',
      },
      skuValForm:{
        space:[],
        adv_id:'',
        show_way:0,
        start_date:'',
        end_date:'',
        pic_url:'',
        sequence:0,
      },
      rules:{
        title: [
          { required: true, message: '请填写标题', trigger: 'blue' },
        ],
        sale_price: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
      },
      skuValrules:{
        space: [
          { required: true, message: '请选择城市', trigger: 'blue' },
        ],
        show_way: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        sale_price: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        pic_url: [
          { required: true, message: '请上传图片', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.onArea()
    // this.keyupSubmit()
  },
  methods:{
    getContent(html){
      this.ruleForm.content=html
      console.log('父组件接受',html)
    },
    /*图片上传*/
    handleAvatarSuccess(res, file) {
      this.logo = URL.createObjectURL(file.raw)
    },
    //LOGO上传图片方法
    httpRequest(options) {
      this.login=true
      // console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
        getUQIMGurl({bucket:'product',ext:'.'+src.substr(6)}).then((res)=>{
          // console.log('获取URL',res)
          if(res.code==0){
            this.UpURL=res.data.upload_url
            let url=res.data.preview_url
            // console.log(this.UpURL)
            getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
              resolve(res)
              this.skuValForm.pic_url=url
              this.login=false
            }).catch((err)=>{
              reject(err)
              this.login=false
            })

          }else {
            this.$message.error('获取上传地址失败！！')
            this.login=false
          }
        })

      })
    },
    //
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
          file.type === "image/png" ||
          file.type === "image/jpg"||
          file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
      // && isLt2M
    },
    /*图片上传*/
    onArea(){
        getArea().then((res)=>{
          this.areaArr=res.data
        }).catch((err)=>{

        })
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getadData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    searchSku(){
      this.pageSku=1
      this.skuValueData(this.skurowData)
    },
    handleSizeChangeSku(val) {
      console.log(`每页 ${val} 条`)
      this.limitSku=val
      this.skuValueData(this.skurowData)
    },
    handleCurrentChangeSku(val) {
      console.log(`当前页: ${val}`)
      this.pageSku=val
      this.skuValueData(this.skurowData)
    },
    skuValueData(row){
      this.skuValName=row.title
      this.skurowData=row
      if(row.platform==0){
        this.space=[
          {
            id:0,
            name:'启动页',
          },
          {
            id:1,
            name:'首页弹框',
          },
        ]
      }else {
        this.space=[
          {
            id:0,
            name:'启动页',
          },
          {
            id:1,
            name:'首页弹框',
          },
          // {
          //   id:2,
          //   name:'页面侧边悬浮',
          // },
        ]
      }
      let form={
        adv_id:this.skurowData.id,
        page:this.pageSku,
        limit:this.limitSku,
        keywords:this.keywords_Sku
      }
      axios.getadValData(form).then((res)=>{
        console.log(res)
        if(res.code==200){
          this.tableDataSku=res.data.data
          this.totalSku=res.data.total
          this.skuValdrawer=true
        }
      }).catch((err)=>{

      })
    },
    addSku(){
      this.skuValForm={
        adv_id:this.skurowData.id,
        space:[],
        show_way:0,
        start_date:'',
        end_date:'',
        pic_url:'',
        sequence:0,
      }
      this.addskuVal=true
    },
    upDataSku(row){
      console.log(row)
      this.skuValForm.id=row.id
      this.skuValForm.adv_id=this.skurowData.id
      this.skuValForm.space=row.space
      this.skuValForm.show_way=row.show_way
      this.skuValForm.start_date=row.start_date
      this.skuValForm.end_date=row.end_date
      this.skuValForm.pic_url=row.pic_url
      this.skuValForm.sequence=row.sequence
      console.log(this.skuValForm)
      this.addskuVal=true
    },
    okSkuValAdd(formName,val){
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddadVal(this.skuValForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.skuValueData(this.skurowData)
                let that=this
                setTimeout(function () {
                  that.addskuVal=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditadVal(this.skuValForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.skuValueData(this.skurowData)
                let that=this
                this.login=false
                setTimeout(function () {
                  that.addskuVal=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleDataSku(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdeladVal({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.skuValueData(this.skurowData)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    add(){
      this.ruleForm={
        title:'',
        platform:0,
        url:'',
        content:'',
      }
      this.drawer=true
    },
    upData(row){
      console.log(row)
      this.ruleForm.id=row.id
      this.ruleForm.title=row.title
      this.ruleForm.platform=row.platform
      this.ruleForm.url=row.url
      this.ruleForm.content=row.content
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddad(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditad(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelad({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.el-checkbox{
  height: 32px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px;
  height: 98px;
  line-height: 98px;
  text-align: center;
}
.avatar {
  width: 98px;
  height: 98px;
  display: block;
}
</style>