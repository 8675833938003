//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*广告接口---------------------------*/
//获取广告列表接口
export function getadData(obj) {
    return request({
        url: '/api/admin/ad',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增广告接口
export function postaddad(obj,url) {
    return request({
        url: '/api/admin/ad/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑广告接口
export function posteditad(obj,url) {
    return request({
        url: '/api/admin/ad/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postdelad(obj,url) {
    return request({
        url: '/api/admin/ad/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

/*广告位接口---------------------------*/
//获取广告位列表接口
export function getadValData(obj) {
    return request({
        url: '/api/admin/ad/space',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增广告位接口
export function postaddadVal(obj,url) {
    return request({
        url: '/api/admin/ad/space/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑广告位接口
export function posteditadVal(obj,url) {
    return request({
        url: '/api/admin/ad/space/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告位接口
export function postdeladVal(obj,url) {
    return request({
        url: '/api/admin/ad/space/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

